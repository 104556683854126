.flex-gallery{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.image-rate{
  position: relative;
  overflow: hidden;
  height: 250px;
  margin: 2.5px;
  flex-grow: 1;
}

.image-rate img{
  width: auto;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image-rate:after{
  content: '⤢';
  cursor: pointer;
  font-weight: bold;
  font-size: 28px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.8);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  transition: all .03s ease-in;
}

.image-rate:hover:after{opacity: 1}

#fullimage{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  cursor: -webkit-zoom-out;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

#fullimage img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 480px){
  .image-rate{
    width: calc(50% - 5px);
    height: calc(50vw - 5px);
  }
}
